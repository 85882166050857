<template>
  <div class="sync-redirect" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)">
    <div class="sync-redirect-header flex justify-between">
      <div class="flex items-center">
        <img class="mg-r-8" src="@images/components/login/logo.png" />
        <span class="fz-18">{{ platformName }}</span>
      </div>
    </div>
    <div class="sync-redirect-main">
      <AnhuiLogin v-if="redirect === 'anhui'" @login="handleLogin" />
      <QuantongLogin v-if="redirect === 'quantong'" @back="handleBack" @login="handleLogin" />
      <AllConnectLogin v-if="redirect === 'ava'" @back="handleBack" />
      <qxlManageLogin v-if="redirect === 'qxl'" @back="handleBack" />
    </div>
    <copyrightHtml />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// components
import copyrightHtml from '@/components/copyrightHtml';
import AnhuiLogin from './components/Anhui-login';
import QuantongLogin from './components/quantong-login';
import AllConnectLogin from './components/all-connect-login';
import qxlManageLogin from './components/qxl-manage-login';
// mixins
import loginDispatchMixin from '@/mixins/loginDispatch';
// config
import { usePathAuthority } from './config';
const { checkJumpAuthority } = usePathAuthority();

export default {
  name: 'sync-redirect',
  mixins: [loginDispatchMixin],
  components: {
    AnhuiLogin,
    QuantongLogin,
    AllConnectLogin,
    qxlManageLogin,
    copyrightHtml,
  },
  data() {
    return {
      isLoading: false,

      // 登录跳转来源(anhui-安徽 ningxia-宁夏 quantong-全通 ava-全连接)
      redirect: '',
    };
  },
  computed: {
    ...mapState({
      platformName: (state) => state.common.platformName,
    }),
  },
  methods: {
    // handle 登录
    handleLogin() {
      this.checkLicenseInfo(() => {
        this.loginDispatch();
      });
    },
    // handle 返回登录页
    async handleBack() {
      await this.$store.dispatch('user/resetToken');
      this.$router.replace('/login');
    },
  },
  created() {
    /**
     * @desc 根据url参数判断跳转
     * @param {isLogin} 已登录（防止回退到登录页）
     * @param {jsessionid|token} 登录账号token
     * @param {login_error} 登录失败标识
     * @param {msg} 登录失败提醒信息
     * @param {schoolInfo} 学校信息
     * @param {thirdProjectCode} 第三方平台code
     * @param {thirdUrl} 第三方平台url
     * @param {thirdToken} 第三方平台token
     * @param {path} 跳转地址
     */
    this.isLoading = true;
    const routeQuery = this.$route.query;

    // 登录成功
    if (
      Object.prototype.hasOwnProperty.call(routeQuery, 'jsessionid') ||
      Object.prototype.hasOwnProperty.call(routeQuery, 'token')
    ) {
      const { jsessionid, token, thirdProjectCode, isLogin } = this.$route.query;
      this.$store.commit('user/SET_TOKEN', jsessionid || token);
      if (thirdProjectCode) {
        // 第三方登录
        // 记录状态，用于第三方登出（宁夏、5G平台）
        localStorage.setItem('thirdProjectCode', thirdProjectCode);
        localStorage.setItem('syncToken', jsessionid || token);
      }
      // 直接登录，不用授权验证
      if (isLogin) {
        this.loginDispatch();
      } else {
        this.handleLogin();
      }
    }

    // 直接登录
    else if (Object.prototype.hasOwnProperty.call(routeQuery, 'isLogin')) {
      this.loginDispatch();
    }

    // 登录失败
    else if (Object.prototype.hasOwnProperty.call(routeQuery, 'login_error')) {
      const { msg } = this.$route.query;
      this.$message.error(msg);
      this.handleBack();
    }

    // 云层登录页面选择学校后跳转
    else if (Object.prototype.hasOwnProperty.call(routeQuery, 'schoolInfo')) {
      const { schoolInfo } = this.$route.query;
      const school = JSON.parse(decodeURIComponent(schoolInfo));
      this.$store.commit('login/setSchoolInfo', school);
      localStorage.setItem('zhkt_school', JSON.stringify(school));
      this.handleBack();
    }

    // 页面跳转
    else if (Object.prototype.hasOwnProperty.call(routeQuery, 'thirdProjectCode')) {
      const { thirdProjectCode, thirdUrl, thirdToken, path } = this.$route.query;
      if (thirdUrl && thirdToken) {
        // 免登录跳转
        this.$store
          .dispatch('user/login', { userInfo: { thirdToken, thirdProjectCode, thirdUrl } })
          .then(async () => {
            const jumpPath = decodeURIComponent(path || '/');
            const { success, result, path: newPath } = await checkJumpAuthority(jumpPath);
            if (success) {
              location.href = newPath;
            } else {
              this.$message.error(result);
              this.handleLogin();
            }
          })
          .catch(() => {
            this.$message.closeAll();
            this.$message.error('请联系管理员检查用户信息是否同步正常');
            this.handleBack();
          });
      } else {
        // 第三方特殊处理
        this.isLoading = false;
        this.redirect = thirdProjectCode;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.sync-redirect {
  position: relative;
  height: 100vh;
  min-height: 780px;
  background: linear-gradient(205deg, #ffffff 0%, #effffa 100%);
  .sync-redirect-header {
    padding: 24px 32px 0 40px;
    img {
      width: 36px;
    }
  }

  .sync-redirect-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 54px);
    padding-bottom: 115px;
  }
}
</style>
