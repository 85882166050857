<template>
  <div class="school-select-box">
    <div class="school-select-box_header">
      <span>选择学校</span>
      <i class="iconfont icon-icon_guanbi float-right cursor-pointer" @click="$emit('back')"></i>
    </div>
    <div class="school-select-box_content" v-loading="loading">
      <div class="search-input">
        <el-input
          size="small"
          placeholder="请输入两个以上连续的关键字"
          v-model.trim="keyword"
          @keyup.native.enter="getSchoolList"
        >
          <i slot="suffix" class="el-input__icon iconfont icon-icon_sousuo cursor-pointer" @click="getSchoolList"></i>
        </el-input>
      </div>
      <div class="school-list">
        <ul v-if="schoolList.length > 0">
          <li
            v-for="item in schoolList"
            :key="item.schoolId"
            :title="item.schoolName"
            :class="['cursor-pointer truncate overflow-ellipsis', { active: schoolKey === item.schoolKey }]"
            @click="selectSchool(item)"
          >
            {{ item.schoolName }}
          </li>
        </ul>
        <qlEmpty type="data" v-else></qlEmpty>
      </div>
    </div>
  </div>
</template>

<script>
// api
import { getSchoolByKeyeordCloud } from '@/api/common';
// components
import { Input } from 'element-ui';
import qlEmpty from '@/components/qlEmpty';

export default {
  name: 'allConnectLogin',
  components: {
    [Input.name]: Input,
    qlEmpty,
  },
  props: {
    defaultData: Array,
  },
  data() {
    return {
      loading: false,
      keyword: '', // 关键字搜索
      schoolKey: '',
      schoolList: [],
    };
  },
  watch: {
    defaultData: {
      immediate: true,
      handler(val) {
        this.schoolList = val || [];
      },
    },
  },
  methods: {
    // 获取学校列表
    async getSchoolList() {
      if (this.keyword.trim().length < 2) {
        this.schoolList = [];
        this.$message.warning('请输入两个以上连续的关键字');
        return;
      }
      this.loading = true;
      getSchoolByKeyeordCloud({ schoolNameFuzzy: this.keyword })
        .then((res) => {
          let { result = [] } = res;
          this.schoolList = result;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 选择学校
    async selectSchool(school) {
      const { schoolKey } = school;
      this.schoolKey = schoolKey;

      this.$emit('select', school);
    },
  },
};
</script>

<style lang="scss" scoped>
.school-select-box {
  @apply bg-A10;
  width: 500px;
  margin: auto;
  border-radius: 8px;

  &_header {
    padding: 20px 32px 19px;
    border-bottom: 1px solid #dee0e3;

    span {
      @apply text-18;
      font-weight: 500;
    }
  }

  &_content {
    .search-input {
      margin: 24px 32px 16px;
    }

    .school-list {
      height: 361px;
      overflow: auto;
      line-height: 34px;
      padding-bottom: 16px;

      li {
        @apply text-F1;
        padding: 0 35px;
      }

      li.active {
        @apply text-theme;
      }
      li:hover {
        background: #f4f6f8;
      }
    }
  }
}
</style>
