<template>
  <div class="sync-redirect_quantong" v-loading="true"></div>
</template>

<script>
// api
import { quantongValida, quantongLogin } from '@/api/back-stage';

export default {
  name: 'quantongLogin',

  methods: {
    // 全通用户验证
    async quantongValida() {
      const { ticket, domain } = this.$route.query;
      if (ticket && domain) {
        const { result } = await quantongValida({ ticket, domain });
        this.quantongLogin(result);
      } else {
        return Promise.reject();
      }
    },

    // 登录
    async quantongLogin(data) {
      if (data) {
        // 全通用户登录
        const { userId, encryption, mobileNo } = data;
        const { result } = await quantongLogin({ userId, encryption, mobileNo });

        if (result) {
          // 青鹿平台登录
          const { thirdToken, thirdProjectCode } = result;
          await this.$store.dispatch('user/login', { userInfo: { thirdToken, thirdProjectCode } });
          this.$emit('login');
        }
      }
    },

    // 返回
    handleBack() {
      this.$emit('back');
    },
  },

  created() {
    this.quantongValida().catch(this.handleBack);
  },
};
</script>

<style lang="scss" scoped></style>
