<template>
  <div class="sync-redirect_anhui">
    <!-- view: 开始同步 -->
    <div class="flex flex-col items-center" v-if="viewStatus === STATUS.READY">
      <img class="img mg-b-16" src="@/assets/images/async-redirect/redirect_sync.png" />
      <p class="msg">该用户所在的学校数据暂未同步至青鹿教学云平台， 请进行同步后再使用。</p>
      <el-button type="primary" style="margin-top: 55px; width: 300px" :loading="buttonLoading" @click="handleSync"
        >开始同步</el-button
      >
    </div>
    <!-- view: 同步状态 -->
    <div class="flex flex-col items-center" v-if="viewStatus === STATUS.LOAGING">
      <img class="img mg-b-16" src="@/assets/images/async-redirect/redirect_sync.png" />
      <div class="msg">{{ schoolName }}-同步完成</div>
      <div class="syncTaskArray">
        <div class="syncTaskArray-item" v-for="item in syncTaskArray" :key="item.itemType">
          <span class="syncTaskArray-item-type">{{ item.itemType }}</span>
          <span class="syncTaskArray-item-status" :class="item.itemStatusClass">{{ item.itemStatus }}</span>
          <span v-if="item.itemStatusClass === 'status_success'">
            <svg class="mg-r-10 fz-16 icon" aria-hidden="true">
              <use xlink:href="#icon-icon_chenggong"></use>
            </svg>
          </span>
          <span v-if="item.itemStatusClass === 'status_fail'">
            <svg class="mg-r-8 fz-16 icon" aria-hidden="true">
              <use xlink:href="#icon-icon_shibai"></use>
            </svg>
          </span>
          <span class="syncTaskArray-item-msg">
            {{ item.itemStatusMsg }}
          </span>
        </div>
      </div>
    </div>
    <!-- view: 同步结果 -->
    <div class="flex flex-col items-center" v-if="viewStatus === STATUS.RESULT">
      <img v-if="syncTaskStatus === 4" class="img" src="@/assets/images/async-redirect/redirect_sync_success.png" />
      <img v-else class="img" src="@/assets/images/async-redirect/redirect_sync.png" />
      <div class="msg">{{ schoolName }}-同步中</div>
      <div class="syncTaskArray">
        <div class="syncTaskArray-item" v-for="item in syncTaskArray" :key="item.itemType">
          <span class="syncTaskArray-item-type">{{ item.itemType }}</span>
          <span class="syncTaskArray-item-status" :class="item.itemStatusClass">{{ item.itemStatus }}</span>
          <span v-if="item.itemStatusClass === 'status_success'">
            <svg class="mg-r-10 fz-16 icon" aria-hidden="true">
              <use xlink:href="#icon-icon_chenggong"></use>
            </svg>
          </span>
          <span v-if="item.itemStatusClass === 'status_fail'">
            <svg class="mg-r-8 fz-16 icon" aria-hidden="true">
              <use xlink:href="#icon-icon_shibai"></use>
            </svg>
          </span>
          <span class="syncTaskArray-item-msg">
            {{ item.itemStatusMsg }}
          </span>
        </div>
      </div>
      <div>
        <el-button v-if="showLog" type="primary" style="width: 160px" class="mg-r-16" @click="handleDownloadLog"
          >下载同步日志</el-button
        >
        <el-button v-if="showLog" style="width: 160px" @click="handleBack">返回登录页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// component
import { Dialog, Button } from 'element-ui';
// api
import { getNewestTaskByAnhuiUserId, startSyncByAnhuiUserId } from '@/api/basic';
// util
import { checkNetworkStatus, downloadFileByA } from '@/utils/common';

const STATUS = {
  READY: '开始同步',
  RESULT: '同步结果',
  LOAGING: '同步状态',
};

const STEP_TYPE = {
  2: '学校',
  3: '学科',
  4: '班级',
  5: '教师',
  6: '学生',
  7: '任教',
};

export default {
  name: 'AnhuiLogin',

  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },

  data() {
    return {
      // const
      STATUS,

      isLoading: false,

      userId: localStorage.getItem('syncUserId') || null,
      schoolName: localStorage.getItem('syncSchoolName') || null,

      syncTaskArray: [
        // 用于显示任务状态
        // {
        //   itemType: '学校',
        //   itemStatus: '同步中...',
        //   itemStatusClass: 'status_syncing',
        //   itemStatusMsg: '',
        // },
      ],

      asyncInterval: null, // 定时轮询
      buttonLoading: false, // 按钮加载

      taskIdUsedByDownloadLog: '', // taskId 用户下载同步日志
    };
  },

  computed: {
    ...mapState({
      syncTaskStatus: (state) => state.sync.syncTaskStatus,
      syncTaskId: (state) => state.sync.syncTaskId,
    }),
    viewStatus() {
      switch (this.syncTaskStatus) {
        case 4: // 同步中
          return STATUS.LOAGING;
        case 2: // 同步失败
        case 3: // 同步完成（部分未成功）
        case 1: // 同步完成
          return STATUS.RESULT;
        case 0: // 待同步
        case 5: // 已关闭
        default:
          return STATUS.READY;
      }
    },
    showLog() {
      if ([0, 1, 2, 3, 5].includes(this.syncTaskStatus)) {
        let item = this.syncTaskArray.find((x) => x.itemStatus !== 1);
        if (item) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    // handle 开始同步
    handleSync() {
      this.buttonLoading = true;

      checkNetworkStatus(
        () => {
          startSyncByAnhuiUserId({
            userId: this.userId,
          }).then(({ result }) => {
            this.$store.commit('sync/SET_SYNC_TASK_ID', result.taskId);
            this.taskIdUsedByDownloadLog = result.taskId;
            this.schoolName = result.schoolName;
            sessionStorage.setItem('syncSchoolName', result.schoolName);

            this.setInterval();
          });
        },
        () => {
          this.$message.error('网络异常！');
          this.buttonLoading = false;
        }
      );
    },
    // handle 下载同步日志
    handleDownloadLog() {
      checkNetworkStatus(
        () => {
          downloadFileByA(`${window.$ctx}/anhuisync/exportTaskLog.do?taskId=${this.taskIdUsedByDownloadLog}`, '', {
            target: '_blank',
          });
        },
        () => {
          this.$message.error('网络异常！');
        }
      );
    },
    // handle 返回登录页
    handleBack() {
      this.$router.push('/login');
    },
    getNewestTask() {
      getNewestTaskByAnhuiUserId({
        userId: this.userId,
      }).then((res) => {
        if (res.result.length === 0) {
          this.$store.commit('sync/SET_SYNC_TASK_ID', null);
          this.$store.commit('sync/SET_SYNC_TASK_STATUS', null);
        } else {
          this.$store.commit('sync/SET_SYNC_TASK_ID', res.result[0].id);
          this.$store.commit('sync/SET_SYNC_TASK_STATUS', res.result[0].status);

          this.schoolName = res.result[0].schoolName;
          location.setItem('syncSchoolName', res.result[0].schoolName);

          this.setSyncTaskArray(res.result);
          if (res.result[0].status === 4) {
            this.setInterval();
          }
        }
      });
    },
    // util 获取任务状态
    async getSyncStatus() {
      let result = await this.$store.dispatch('sync/getSyncStatus');
      this.setSyncTaskArray(result);
      if (result[0].status === 4) {
        this.setInterval();
      } else {
        this.$store.commit('sync/SET_SYNC_TASK_ID', '');
        this.clearInterval();
      }
    },
    // util syncTaskArray
    setSyncTaskArray(data) {
      this.syncTaskArray = [];

      for (let i = 1; i < data.length; i++) {
        let itemType = STEP_TYPE[data[i].type];
        let itemStatus = '';
        let itemStatusClass = '';
        let itemStatusMsg = '';

        switch (data[i].status) {
          case 0:
            // 刚开始同步任务时，对学校设置同步中状态，为了让用户看了心理舒服点
            if (data[i].type == 2) {
              itemStatus = '同步中...';
              itemStatusClass = 'status_syncing';
            } else {
              itemStatus = '待同步';
              itemStatusClass = 'status_ready';
            }
            break;
          case 1:
            itemStatus = '同步完成';
            itemStatusClass = 'status_success';
            break;
          case 2:
            itemStatus = '同步失败';
            itemStatusClass = 'status_fail';
            break;
          case 3:
            itemStatus = '同步完成';
            itemStatusClass = 'status_success';
            itemStatusMsg = '部分信息未同步成功，请查看日志';
            break;
          case 4:
            itemStatus = '同步中...';
            itemStatusClass = 'status_syncing';
            break;
        }

        this.syncTaskArray.push({
          status: data[i].status,
          itemType,
          itemStatus,
          itemStatusClass,
          itemStatusMsg,
        });
      }
    },
    // util interval
    setInterval() {
      if (!this.asyncInterval) {
        this.asyncInterval = setInterval(() => {
          this.getSyncStatus();
        }, 10000);
      }
    },
    // util interval
    clearInterval() {
      if (this.asyncInterval) {
        clearInterval(this.asyncInterval);
        this.asyncInterval = null;
      }
    },
  },
  destroyed() {
    this.clearInterval();
  },
  async created() {
    const { isSchoolRegister, thirdToken, thirdProjectCode } = this.$route.query;

    if (isSchoolRegister === 'true') {
      this.isLoading = true;
      await this.$store.dispatch('user/login', { userInfo: { thirdToken, thirdProjectCode } });
      this.isLoading = false;
      this.$emit('login');
    } else {
      this.userId = thirdToken;
      sessionStorage.setItem('syncUserId', thirdToken);

      if (this.syncTaskId) {
        this.getNewestTask();
      }
    }
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
