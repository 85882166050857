import { getHomeMenu } from '@/api/common';

export const usePathAuthority = () => {
  // 平台已有的权限模块
  const Modules = {
    class: {
      serverName: '智慧课堂',
      serverId: 5,
    },
    exercise: {
      serverName: '智慧测练',
      serverId: 3,
    },
    homework: {
      serverName: '教辅作业',
      serverId: 6,
      name: '教辅作业',
      code: 'jiaofuzuoye',
      path: '/qingxiaolu-exercises/#/teachingHomework/teachingList?type=2',
    },
    bigData: {
      serverName: '智慧课堂',
      serverId: 5,
      name: '教学数据大屏',
      code: 'databoardScreen',
      msg: '该用户暂无权限访问',
    },
  };

  // 已对外提供的免登录跳转地址，并需要做权限拦截
  const jumpPaths = {
    '/qingxiaolu-exercises/#/afterClassExercises/list?type=1_2': { ...Modules.exercise }, // 自制作业
    '/qingxiaolu-exercises/#/afterClassExercises/list?type=2': { ...Modules.homework }, // 预制作业
    '/wisdomClassVue/#/academicAnalysis/monitorReport': { ...Modules.homework }, // 作业监测报告
    '/wisdomClassVue/#/analysis': { ...Modules.class }, // 课堂分析报告
    '/campusData/#/dataPage': { ...Modules.bigData }, // 数据看板
  };

  // 判断是否需要拦截跳转
  const checkJumpAuthority = async (path) => {
    let currentModule = jumpPaths[path];
    if (currentModule) {
      const { result } = await getHomeMenu();
      const modules = result.menuNewList || [];
      const activeModule = modules.find((m) => m.serverId === currentModule.serverId);
      if (
        activeModule &&
        (!currentModule.code || activeModule.menuList.some((menu) => menu.code === currentModule.code))
      ) {
        return {
          success: true,
          path: currentModule.path || path,
        };
      } else {
        return {
          success: false,
          result: currentModule.msg || `该学校暂未开通${currentModule.name || currentModule.serverName}模块`,
        };
      }
    } else {
      return { success: true, path };
    }
  };

  return {
    checkJumpAuthority,
  };
};
