<template>
  <schoolSelectBox :defaultData="schoolList" @select="selectSchool" @back="handleBack" />
</template>

<script>
// components
import schoolSelectBox from '../school-select-box';

// 青小鹿管理
export default {
  name: 'qxl-manage-login',
  components: {
    schoolSelectBox,
  },
  data() {
    return {
      schoolList: [],
    };
  },
  methods: {
    // 选择学校
    async selectSchool(school) {
      const { schoolKey, serverUrl } = school;
      location.href = `${serverUrl}/OAuth2/qxl/authorize.do?schoolKey=${schoolKey}&moduleId=21`;
    },

    // 返回
    handleBack() {
      this.$emit('back');
    },
  },
};
</script>
