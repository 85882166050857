<template>
  <schoolSelectBox :defaultData="schoolList" @select="selectSchool" @back="handleBack" />
</template>

<script>
// components
import schoolSelectBox from '../school-select-box';

export default {
  name: 'allConnectLogin',
  components: {
    schoolSelectBox,
  },
  data() {
    return {
      schoolList: [],
    };
  },
  methods: {
    // 选择学校
    async selectSchool(school) {
      const { schoolKey, serverUrl } = school;
      localStorage.setItem('avaAuthorizeSchool', JSON.stringify(school));
      location.href = `${serverUrl}/OAuth2/ava/authorize.do?schoolKey=${schoolKey}&moduleId=21`;
    },

    // 返回
    handleBack() {
      this.$emit('back');
    },
  },
  created() {
    const school = localStorage.getItem('avaAuthorizeSchool');
    this.schoolList = school && new Array(JSON.parse(school));
  },
};
</script>
