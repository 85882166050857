// api
import { getLicenseInfo, setLicenseTipsStatus } from '@/api/common';
// util
import { setURLParams } from '@/utils/common';
// import { getRouteByPermission } from '@/utils/permission';

export default {
  methods: {
    // 登录跳转
    async loginDispatch(serverUrl) {
      // await getRouteByPermission();

      if (process.env.NODE_ENV === 'development') {
        // 开发环境进入后台管理
        this.$router.push('/back-stage');
      } else {
        // 直接进入教学云平台首页
        const path = `${serverUrl || location.origin}/#/redirect`;
        let { redirect } = this.$route.query;
        let params = {
          redirect: (redirect && encodeURIComponent(redirect)) || null,
        };
        window.location.replace(`${path}${setURLParams(params)}`);
      }
    },

    // 获取redirect（登录后跳转路由）
    // async getRedirect(router) {
    //   // 检查是否拥有该路由的权限
    //   const checkRouteIncludes = (redirect) => {
    //     const getPaths = (routes, rootPath = '') => {
    //       routes.map((route) => {
    //         let path = `${rootPath}${route.path}`;
    //         paths.push(path);
    //         if (route.children) {
    //           getPaths(route.children, `${path}/`);
    //         }
    //       });
    //     };

    //     let [...routes] = this.$store.state.route.routes;
    //     let paths = [];
    //     getPaths(routes);
    //     return paths.includes(redirect);
    //   };

    //   const redirect = this.$route.query.redirect;
    //   if (redirect && redirect !== '/') {
    //     if (checkRouteIncludes(redirect)) {
    //       return redirect;
    //     }
    //   }
    //   return router;
    // },

    /**
     * @desc 打开授权提示弹框
     * @param {number} status 接口返回的登录授权状态
     * @param {object} result 授权提示时间结果
     * @param {string} msg 报错信息
     * @param {boolean} isBack 是否返回登录页
     * @param {function} callback 成功回调
     */
    openLicenseToast({ status, result, msg, isBack, callback }) {
      let title = '';
      let content = '';
      let reject = false; // 拒绝登录
      let isSuccess = false; // 成功状态
      let needAuthority = false; // 需要角色权限
      let cancelText = '';
      const h = this.$createElement;
      switch (status) {
        case 0: // 成功
        case 1002: // 授权即将到期(已勾选不提醒)
          isSuccess = true;
          break;
        case 1000: // 授权未生效
          title = '学校授权未生效';
          content = h('p', { class: 'text-A5' }, `系统将于${result.beginDate}开始生效`);
          reject = true;
          break;
        case 1001: // 授权即将到期(未勾选不提醒)
          title = h('p', null, [
            h('span', null, '距离学校授权到期还有'),
            h('span', { class: 'text-A3' }, result?.endDays || '--'),
            h('span', null, '天'),
          ]);
          content = '请联系青鹿工作人员更新授权';
          cancelText = '已知晓，今日不再提醒';
          needAuthority = true;
          break;
        case 1003: // 授权已过期
          title = '学校授权已过期';
          content = '请联系青鹿工作人员更新授权';
          reject = true;
          break;
      }

      if (title && content) {
        // 非校层管理员不用提示
        if (needAuthority && !this.$store.getters.isSchoolAdmin) {
          callback && callback();
          return;
        }
        this.$toast({
          type: 'warning',
          title,
          content,
          width: '400px',
          confirmText: '知道了',
          cancelText,
          confirmCallback: () => {
            if (reject) {
              this.backToLoginPage();
            } else {
              callback && callback();
            }
          },
          cancelCallback: () => {
            setLicenseTipsStatus({
              schoolId: this.$store.getters.schoolId,
              userId: this.$store.getters.userInfo.userId,
            }).then(() => {
              callback && callback();
            });
          },
        });
      } else if (isSuccess) {
        callback && callback();
      } else {
        this.errorToast(msg);
        if (status === 100 && isBack) {
          this.backToLoginPage();
        }
      }
    },

    // 授权提示
    checkLicenseInfo(callback) {
      getLicenseInfo({
        schoolId: this.$store.getters.schoolId,
        userId: this.$store.getters.userInfo.userId,
      }).then(({ data }) => {
        const { status, result, msg } = data;
        this.openLicenseToast({ status, result, msg, callback });
      });
    },

    // 错误提醒
    errorToast(msg) {
      this.$message.closeAll();
      this.$message.error(msg || '网络服务异常，请检查网络');
    },

    // 退出到登录页面
    backToLoginPage() {
      this.$store.dispatch('user/resetToken');
      this.$route.path !== '/login' && this.$router.replace('/login');
    },
  },
};
